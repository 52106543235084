import axios from "axios";
import Cookies from "js-cookie";
const client = axios.create({ baseURL: "https://api.bobio.gomaplus.tech/api" });
export const request = async ({ ...options }) => {
  client.defaults.headers.common.Authorization = `Bearer ${Cookies.get(
    "popio_token"
  )}`;
  return client(options).then((res) => res);
};

export const baseImageUrl = "https://api.bobio.gomaplus.tech";
